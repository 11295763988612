import "./services.css"
import localImg from "../../Pictures/localDistImg.jpg"
import longDistImg from "../../Pictures/longDistImg.jpg"
import packImg from "../../Pictures/PackImg.jpg"
import storageImg from "../../Pictures/storageImg.jpg"
import junkImg from "../../Pictures/junkImg.jpg"
import specialtyImg from "../../Pictures/specialtyImg.jpg"
import { LocalMovingText, 
        LongDistanceMovingText, 
        PackingServicesText, 
        JunkRemovalText, 
        SpecialtyItemText, 
        StorageServicesText} from "../../TextFile"

const SingleService = ({title, description, image}) => {
    return (
        <div className="service-card">
            <img loading='lazy' src={image} alt={title} className="service-image" />
            <h3 className="service-title">{title}</h3>
            <p className="service-description">
                {description}
            </p>
        </div>
    );
}

function ServicesSection(){
    return (
        <div className="Section">
            <div className="services-container">
                <h2 className="services-heading">Our Services</h2>
                <div className="services-grid">
                    <SingleService title='Local Moving' description={LocalMovingText()} image={localImg}/>
                    <SingleService title='Long-Distance Moving' description={LongDistanceMovingText()} image={longDistImg} />
                    <SingleService title='Packing Services' description={PackingServicesText()} image={packImg} />
                    <SingleService title='Storage Solutions' description={StorageServicesText()} image={storageImg} />
                    <SingleService title='Junk Removal' description={JunkRemovalText()} image={junkImg} />
                    <SingleService title='Specialty Item Moving' description={SpecialtyItemText()} image={specialtyImg} />                        
                </div>
            </div>
        </div>
    );
}

export default ServicesSection;