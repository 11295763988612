import './aboutSection.css';
import '../Contact/contactForm.css';
import mockPicture from "../../Pictures/Team.jpeg"
import { AboutText } from '../../TextFile';

function AboutSection(){
    return(
        <div className="section-container">
            <h2 className="section-title">All About Stonehands Moving </h2>
            <div className="content-container">
                <img src={mockPicture} alt='stonehands moving team' className="section-image"/>
                <div className="section-text">
                    {AboutText()}
                </div>
            </div>
        </div>
    );
}

export default AboutSection;